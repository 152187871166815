import { GET_MEDIA_ANALYSIS_ROWS } from '@/lib/MediaAnalysisApi';
import MediaAnalysisTable from './MediaAnalysisTable';
import { SearchOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, DatePicker, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { useState, useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

export type MediaAnalysisRow = {
	id: string;
	platformId: string;
	reportId: string;
	agency: string;
	currency: string;
	exchangeRate: number;
	client: string;
	ioOrPo: string;
	campaign: string;
	product: string[];
	formats: string[];
	businessModel: string[];
	buyingType: string;
	rate: number;
	orderNumber: string;
	onTvl: boolean;
	investment: number;
	monthlyBilling: number;
	objectiveSpend: number;
	Platform: string;
	cost: number;
	comment: string;
	startDate: string;
	endDate: string;
	market: string;
};

const accountManagersQuery = gql`
	query {
		accountManagers {
			id
			name
		}
	}
`;

const MediaAnalysisPage = () => {
	const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
	const [selectedAccountManager, setSelectedAccountManager] = useState<
		string | null
	>(null);

	const [getMediaAnalysisRows, { loading, error, data }] = useLazyQuery<{
		getMediaAnalysisRows: MediaAnalysisRow[];
	}>(GET_MEDIA_ANALYSIS_ROWS, { fetchPolicy: 'network-only' });

	const { data: accountManagers, loading: accountsManagersLoading } = useQuery<{
		accountManagers: { id: string; name: string }[];
	}>(accountManagersQuery);

	const refetch = async () => {
		await getMediaAnalysisRows({
			variables: {
				month:
					selectedMonth && selectedMonth.startOf('month').format('YYYY-MM-DD'),
				accountManager: selectedAccountManager,
			},
		});
		console.log('Refetched');
	};

	const handleSubmit = () => {
		if (selectedMonth) {
			getMediaAnalysisRows({
				variables: {
					month:
						selectedMonth &&
						selectedMonth.startOf('month').format('YYYY-MM-DD'),
					accountManager: selectedAccountManager,
				},
			});
		}
	};

	const { user } = useContext(AuthContext);

	return (
		<div>
			{user?.permission?.mediaAnalysisDataSeePage === 'yes' ? (
				<>
					<h3 className="mb-1 mt-0 text-xl font-medium">Media Analysis page</h3>
					<p className="mb-6 mt-0 text-sm font-light">
						Select month and account manager to get data rows
					</p>
					<p className="mb-6 mt-0 text-sm font-light">
						Cambiar esto getFinancialRows por getFMediaAnalysisRows y generar la
						nueva lógica en extends del back y luego cambiar la query en el
						front.
					</p>
					<div className="mb-4 flex items-center gap-x-2">
						<DatePicker
							picker="month"
							onChange={setSelectedMonth}
							placeholder="Select month"
						/>
						<Select
							className="w-52"
							placeholder="Select account manager"
							value={selectedAccountManager}
							onChange={setSelectedAccountManager}
							loading={accountsManagersLoading}
							options={accountManagers?.accountManagers.map(({ id, name }) => ({
								value: id,
								label: name,
							}))}
						/>
						<Button
							disabled={!selectedMonth || !selectedAccountManager}
							onClick={handleSubmit}
							type="primary"
							icon={<SearchOutlined />}>
							Search media analysis
						</Button>
					</div>

					{loading && data?.getMediaAnalysisRows.length === 0 && (
						<p>Loading...</p>
					)}
					{error && <p>{error.message}</p>}
					{data && (
						<MediaAnalysisTable
							rows={data.getMediaAnalysisRows}
							refetch={refetch}
							loading={loading}
							selectedAccountManager={selectedAccountManager}
							accountManagers={accountManagers?.accountManagers ?? []}
						/>
					)}
				</>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default MediaAnalysisPage;
