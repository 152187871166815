import { Form, Table, TableColumnsType } from 'antd';
import { MediaAnalysisRow } from './MediaAnalysisPage';

type Props = {
	rows: MediaAnalysisRow[];
	refetch: () => Promise<void>;
	loading: boolean;
	selectedAccountManager: string | null;
	accountManagers: { id: string; name: string }[];
};

const MediaAnalysisTable = ({
	rows,
	refetch,
	selectedAccountManager,
	accountManagers,
}: Props) => {
	const [form] = Form.useForm();

	const accountManagerName = accountManagers.find(
		(manager) => manager.id === selectedAccountManager
	)?.name;

	const tableColumns: TableColumnsType<MediaAnalysisRow> = [
		{
			title: 'Account Manager',
			dataIndex: 'accountManager',
			key: 'accountManager',
			fixed: true,
			render: () => accountManagerName || 'N/A',
		},
		{
			title: 'Market',
			dataIndex: 'market',
			key: 'market',
			fixed: true,
		},
		{
			title: 'Agency',
			dataIndex: 'agency',
			key: 'agency',
			fixed: true,
		},
		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
			fixed: true,
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			fixed: true,
		},
		{
			title: 'Sub Campaign',
			dataIndex: 'platformName',
			key: 'platformName',
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
		},
		{
			title: 'Formats',
			dataIndex: 'formats',
			key: 'formats',
			render: (formats) => formats.join(', '),
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			render: (businessModels) => businessModels.join(', '),
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			key: 'buyingType',
			render: (buyingType) => (buyingType ? buyingType : 'N/A'),
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			render: (rate) => (rate ? `$${rate}` : 'N/A'),
		},
		{
			title: 'Spend',
			dataIndex: 'objectiveSpend',
			key: 'objectiveSpend',
			render: (objectiveSpend) =>
				objectiveSpend ? `$${objectiveSpend}` : 'N/A',
		},
		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
		},
	];

	return (
		<div className="rounded-lg border border-gray-200 bg-white p-1 shadow">
			<Form form={form}>
				<Table
					size="small"
					bordered
					pagination={false}
					scroll={{ x: 2500 }}
					columns={tableColumns}
					rowKey={(record) => record.id}
					dataSource={rows}></Table>
			</Form>
		</div>
	);
};

export default MediaAnalysisTable;
