import { GET_FINANCIAL_ROWS } from '@/lib/ReconciliationApi';
import ReconciliationTable from '@/routes/ReconciliationPage/ReconciliationTable';
import { SearchOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, DatePicker, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { useState, useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

export type FinancialRow = {
	id: string;
	platformId: string;
	reportId: string;
	agency: string;
	currency: string;
	exchangeRate: number;
	client: string;
	ioOrPo: string;
	campaign: string;
	product: string[];
	formats: string[];
	businessModel: string[];
	buyingType: string;
	rate: number;
	orderNumber: string;
	onTvl: boolean;
	investment: number;
	monthlyBilling: number;
	Platform: string;
	cost: number;
	comment: string;
	startDate: string;
	endDate: string;
};

const accountManagersQuery = gql`
	query {
		accountManagers {
			id
			name
		}
	}
`;

const ReconciliationPage = () => {
	const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
	const [selectedAccountManager, setSelectedAccountManager] = useState<
		string | null
	>(null);

	const [getFinancialRows, { loading, error, data }] = useLazyQuery<{
		getFinancialRows: FinancialRow[];
	}>(GET_FINANCIAL_ROWS, { fetchPolicy: 'network-only' });

	const { data: accountManagers, loading: accountsManagersLoading } = useQuery<{
		accountManagers: { id: string; name: string }[];
	}>(accountManagersQuery);

	const refetch = async () => {
		await getFinancialRows({
			variables: {
				month:
					selectedMonth && selectedMonth.startOf('month').format('YYYY-MM-DD'),
				accountManager: selectedAccountManager,
			},
		});
		console.log('Refetched');
	};

	const handleSubmit = () => {
		if (selectedMonth) {
			getFinancialRows({
				variables: {
					month:
						selectedMonth &&
						selectedMonth.startOf('month').format('YYYY-MM-DD'),
					accountManager: selectedAccountManager,
				},
			});
		}
	};

	const { user } = useContext(AuthContext);

	return (
		<div>
			{user?.permission?.reconciliationDataSeePage === 'yes' ? (
				<>
					<h3 className="mb-1 mt-0 text-xl font-medium">Reconciliation page</h3>
					<p className="mb-6 mt-0 text-sm font-light">
						Select month and account manager to get financial rows
					</p>
					<div className="mb-4 flex items-center gap-x-2">
						<DatePicker
							picker="month"
							onChange={setSelectedMonth}
							placeholder="Select month"
						/>
						<Select
							className="w-52"
							placeholder="Select account manager"
							value={selectedAccountManager}
							onChange={setSelectedAccountManager}
							loading={accountsManagersLoading}
							options={accountManagers?.accountManagers.map(({ id, name }) => ({
								value: id,
								label: name,
							}))}
						/>
						<Button
							disabled={!selectedMonth || !selectedAccountManager}
							onClick={handleSubmit}
							type="primary"
							icon={<SearchOutlined />}>
							Search sub campaigns
						</Button>
					</div>

					{loading && data?.getFinancialRows.length === 0 && <p>Loading...</p>}
					{error && <p>{error.message}</p>}
					{data && (
						<ReconciliationTable
							rows={data.getFinancialRows}
							refetch={refetch}
							loading={loading}
						/>
					)}
				</>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default ReconciliationPage;
