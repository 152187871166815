import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	InputNumber,
	TimeRangePickerProps,
} from 'antd';
import { FC } from 'react';
import BusinessModelSelect from './BusinessModelSelect';
import BuyingTypeAndRateField from './BuyingTypeAndRateField';
import DevicesSelect from './DevicesSelect';
import ObjectiveTypeSelect from './ObjectiveTypeSelect';
import OtherVariablesSelect from './OtherVariablesSelect';
import PlatformFormatsSelect from './PlatformFormatSelect';
import ReportingSelect from './ReportingSelect';
import SecondaryKPIField from './SecondaryKPIField';
import StatusSelect from './StatusSelect';
import YesNoPendingSelect from './YesNoPendingSelect';

type Props = {
	form: FormInstance<any>;
};

const PlatformsFields: FC<Props> = ({ form }: Props) => {
	const disabledDate: TimeRangePickerProps['disabledDate'] = (current) => {
		return (
			form.getFieldValue('timePeriod') &&
			current &&
			(current.unix() <
				form.getFieldValue('timePeriod')[0].startOf('day').unix() ||
				current.unix() >
					form.getFieldValue('timePeriod')[1].endOf('day').unix())
		);
	};
	return (
		<>
			<Form.List name="platforms">
				{(fields, { add, remove }) => (
					<div>
						{fields.map((field, index) => (
							<div key={index}>
								<Form.Item
									name={[field.name, 'name']}
									label="Name"
									rules={[{ required: true, message: 'Please add a name' }]}>
									<Input placeholder="Name" />
								</Form.Item>
								<Form.Item label="Platform & format">
									<PlatformFormatsSelect fieldName={field.name} />
								</Form.Item>
								<Form.Item
									name={[field.name, 'businessModel']}
									label="Business model"
									rules={[
										{ required: true, message: 'Please add a business model' },
									]}>
									<BusinessModelSelect placeholder="Business Model" />
								</Form.Item>
								<Form.Item label="Fee" name={[field.name, 'fee']}>
									<InputNumber min={0} style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item
									label="Time period"
									name={[field.name, 'timePeriod']}
									rules={[
										{
											required: true,
											message: 'Please select a valid date range',
										},
									]}>
									<DatePicker.RangePicker
										disabledDate={disabledDate}
										className="w-full"
									/>
								</Form.Item>
								<Form.Item label="Devices" name={[field.name, 'devices']}>
									<DevicesSelect
										placeholder="Devices"
										onChange={(value) =>
											form.setFieldsValue({ devices: value })
										}
									/>
								</Form.Item>
								<Form.Item
									label="Other variables"
									name={[field.name, 'otherVariables']}>
									<OtherVariablesSelect
										placeholder="Other variables"
										onChange={(value) =>
											form.setFieldsValue({ otherVariables: value })
										}
									/>
								</Form.Item>
								<Form.Item label="Buying Type & rate">
									<BuyingTypeAndRateField
										fieldName={[field.name, 'rate']}
										prefixName={[field.name, 'buyingType']}
									/>
								</Form.Item>
								<Form.Item
									{...field}
									label="Objective"
									rules={[
										{
											required: true,
											message: 'Please input a valid objective',
										},
									]}>
									<ObjectiveTypeSelect
										fieldName={[field.name, 'objectiveValue']}
										prefixName={[field.name, 'objectiveType']}
									/>
								</Form.Item>
								<Form.Item {...field} label="Secondary KPI">
									<SecondaryKPIField
										prefixName={[field.name, 'secondaryKPIType']}
										fieldName={[field.name, 'secondaryKPIValue']}
									/>
								</Form.Item>
								<Form.Item
									initialValue={0}
									{...field}
									label="Cost"
									name={[field.name, 'cost']}>
									<InputNumber
										addonBefore="$"
										style={{ width: '100%' }}
										min={0}
									/>
								</Form.Item>
								<Form.Item
									initialValue={0}
									{...field}
									label="Investment"
									name={[field.name, 'investment']}
									rules={[
										{
											required: true,
											message: 'Please input a valid investment',
										},
									]}>
									<InputNumber
										addonBefore="$"
										style={{ width: '100%' }}
										min={0}
									/>
								</Form.Item>
								<Form.Item label="CTR%" name={[field.name, 'ctr']}>
									<InputNumber min={0} style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item label="VTR%" name={[field.name, 'vtr']}>
									<InputNumber min={0} style={{ width: '100%' }} />
								</Form.Item>
								<Form.Item label="Reporting" name={[field.name, 'reporting']}>
									<ReportingSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Reporting"
									/>
								</Form.Item>
								<Form.Item
									label="Final Report"
									name={[field.name, 'finalReport']}>
									<YesNoPendingSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Final Report"
									/>
								</Form.Item>
								<Form.Item label="IO or PO" name={[field.name, 'ioOrPo']}>
									<YesNoPendingSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="IO or PO"
									/>
								</Form.Item>
								<Form.Item
									label="Screenshots"
									name={[field.name, 'screenshots']}>
									<YesNoPendingSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Screenshots"
									/>
								</Form.Item>
								<Form.Item
									label="Reconciliation"
									name={[field.name, 'reconciliation']}>
									<YesNoPendingSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Reconciliation"
									/>
								</Form.Item>
								<Form.Item label="Status" name={[field.name, 'status']}>
									<StatusSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Status"
									/>
								</Form.Item>
								{index === fields.length - 1 && (
									<Button
										className="w-full"
										onClick={() => remove(field.name)}
										danger
										icon={<MinusCircleOutlined />}>
										Remove sub campaign
									</Button>
								)}
								<Divider />
							</div>
						))}
						<Button
							type="dashed"
							onClick={() => add()}
							block
							icon={<PlusOutlined />}>
							Add sub campaign
						</Button>
					</div>
				)}
			</Form.List>
		</>
	);
};

export default PlatformsFields;
