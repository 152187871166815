import { gql } from 'graphql.macro';

const GET_MEDIA_ANALYSIS_ROWS = gql`
	query GET_MEDIA_ANALYSIS_ROWS($month: String!, $accountManager: ID!) {
		getMediaAnalysisRows(month: $month, accountManager: $accountManager) {
			id
			agency
			currency
			exchangeRate
			buyingType
			businessModel
			investment
			cost
			monthlyBilling
			objectiveSpend
			comment
			platform
			rate
			onTvl
			orderNumber
			platformName
			campaign
			formats
			client
			product
			startDate
			endDate
			platformId
			reportId
			market
		}
	}
`;

// const UPDATE_MEDIA_ANALYSIS_ROW = gql`
// 	mutation UPDATE_FINANCIAL_ROW($rowID: String!, $data: FinancialRowInput!) {
// 		updateFinancialRow(rowID: $rowID, data: $data) {
// 			id
// 			financialRows
// 		}
// 	}
// `;

// export { GET_MEDIA_ANALYSIS_ROWS, UPDATE_MEDIA_ANALYSIS_ROW };

export { GET_MEDIA_ANALYSIS_ROWS };

// ESTA LA CONSULTA DE MEDIA ANALYSIS TENGO QUE HACERLO EN EL BACK EXTENSION Y LUEGO EN EL FRONT
// query GetMediasFilters {
//   reports(
//     where: {
//       accountManager: { name: { equals: "Alejandra Valdez" } },
//       platforms: {
//         some: { objectiveType: { equals: "absolute" } }
//       }
//     }
//   ) {
//     accountManager {
//       name
//     }
//     market {
//       name
//     }
//     platforms(where: { objectiveType: { equals: "absolute" } }) {
//       status
//       platform {
//         name
//       }
//       formats {
//         name
//       }
//       rate
//       cost
//       buyingType
//       name
//       objectiveSpend
//       businessModel
//       objectiveValue
//       objectiveType
//     }
//     client {
//       name
//     }
//     agency {
//       name
//       incentive
//     }
//     investment
//     exchangeRate
//   }
// }
