import {
	CreateReportPlatformData,
	CreateReportPlatformVariables,
	CREATE_REPORT_PLATFORM,
} from '@/lib/reportsApi';
import BusinessModelSelect from '@/routes/DataPage/FormFields/BusinessModelSelect';
import BuyingTypeAndRateField from '@/routes/DataPage/FormFields/BuyingTypeAndRateField';
import DevicesSelect from '@/routes/DataPage/FormFields/DevicesSelect';
import ObjectiveTypeSelect from '@/routes/DataPage/FormFields/ObjectiveTypeSelect';
import OtherVariablesSelect from '@/routes/DataPage/FormFields/OtherVariablesSelect';
import PlatformFormatsSelect from '@/routes/DataPage/FormFields/PlatformFormatSelect';
import ReportingSelect from '@/routes/DataPage/FormFields/ReportingSelect';
import SecondaryKPIField from '@/routes/DataPage/FormFields/SecondaryKPIField';
import StatusSelect from '@/routes/DataPage/FormFields/StatusSelect';
import YesNoPendingSelect from '@/routes/DataPage/FormFields/YesNoPendingSelect';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
	Button,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Modal,
	TimeRangePickerProps,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { FC, useState } from 'react';

type Props = {
	report: Record<string, string>;
	refetch: any;
	refetchReports: () => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 },
	},
};

const AddPlatformModal: FC<Props> = ({
	report,
	refetch,
	refetchReports,
}: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [objectiveType, setObjectiveType] = useState<string | undefined>();

	const [createReportPlatformMutation] = useMutation<
		CreateReportPlatformData,
		CreateReportPlatformVariables
	>(CREATE_REPORT_PLATFORM);
	const [form] = useForm();

	const showModal = () => {
		setIsModalOpen(true);
		form.resetFields();
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const disabledDate: TimeRangePickerProps['disabledDate'] = (current) => {
		return (
			current &&
			(current.unix() > moment(report.endDate).endOf('day').unix() ||
				current.unix() < moment(report.startDate).startOf('day').unix())
		);
	};

	const onFinish = async (values: any) => {
		const platform = {
			...values,
			startDate: values.timePeriod![0].format('YYYY-MM-DD'),
			endDate: values.timePeriod![1].format('YYYY-MM-DD'),
			platform: values.platform.platform
				? { connect: { id: values.platform.platform } }
				: null,
			formats: values.platform.formats
				? {
						connect: values.platform.formats.map((f: any) => ({ id: f })),
				  }
				: null,
		};
		delete platform.timePeriod;
		await createReportPlatformMutation({
			variables: { data: { ...platform }, id: report.id },
		});
		setIsModalOpen(false);
		refetch();
		refetchReports();
	};

	const onFinishFailed = (values: any) => {};

	const handleObjectiveTypeChange = (value: string) => {
		setObjectiveType(value);
		form.setFieldsValue({ objectiveType: value, rate: undefined });
	};

	return (
		<div className="mb-2">
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					onClick={showModal}
					size="small">
					Add sub campaign
				</Button>
			</div>
			<Modal
				width={675}
				bodyStyle={{
					paddingTop: '20px',
					paddingRight: '10px',
					maxHeight: 'calc(100vh - 300px)',
					overflow: 'auto',
				}}
				title={'New Sub Campaign'}
				open={isModalOpen}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer={[
					<Button key="cancel" onClick={handleCancel} danger>
						Cancel
					</Button>,
					<Button
						form="createPlatform"
						key="submit"
						htmlType="submit"
						type="primary">
						Submit
					</Button>,
				]}>
				<Form
					id="createPlatform"
					layout="horizontal"
					form={form}
					{...formItemLayout}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="name"
						label="Name"
						rules={[{ required: true, message: 'Please add a name' }]}>
						<Input placeholder="Name" />
					</Form.Item>
					<Form.Item
						label="Platform & format"
						rules={[
							{
								required: true,
								message: 'Please select a valid platform and formats',
							},
						]}>
						<PlatformFormatsSelect fieldName="platform" />
					</Form.Item>
					<Form.Item
						name="businessModel"
						label="Business model"
						rules={[
							{ required: true, message: 'Please add a business model' },
						]}>
						<BusinessModelSelect placeholder="Business Model" />
					</Form.Item>
					<Form.Item label="Fee" name="fee">
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item
						label="Time period"
						name="timePeriod"
						rules={[
							{ required: true, message: 'Please select a valid date range' },
						]}>
						<DatePicker.RangePicker
							disabledDate={disabledDate}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item label="Devices" name="devices">
						<DevicesSelect
							placeholder="Devices"
							onChange={(value) => form.setFieldsValue({ devices: value })}
						/>
					</Form.Item>
					<Form.Item label="Other variables" name="otherVariables">
						<OtherVariablesSelect
							placeholder="Other variables"
							onChange={(value) =>
								form.setFieldsValue({ otherVariables: value })
							}
						/>
					</Form.Item>
					<Form.Item
						label="Objective"
						required
						rules={[
							{
								required: true,
								message: 'Please input a valid objective',
							},
						]}>
						<ObjectiveTypeSelect
							fieldName="objectiveValue"
							prefixName="objectiveType"
							onChange={handleObjectiveTypeChange}
						/>
					</Form.Item>
					{objectiveType === 'absolute' && (
						<Form.Item
							label="Buying Type & rate"
							required
							rules={[
								{ required: true, message: 'Please input a valid quantity' },
							]}>
							<BuyingTypeAndRateField
								fieldName="rate"
								prefixName="buyingType"
							/>
						</Form.Item>
					)}
					<Form.Item label="Secondary KPI">
						<SecondaryKPIField
							prefixName="secondaryKPIType"
							fieldName="secondaryKPIValue"
						/>
					</Form.Item>
					<Form.Item label="Cost" name="cost" initialValue={0}>
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item label="Budget" name="budget" initialValue={0}>
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item
						label="Investment"
						name="investment"
						initialValue={0}
						required>
						<InputNumber addonBefore="$" style={{ width: '100%' }} min={0} />
					</Form.Item>
					<Form.Item label="CTR%" name="ctr">
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item label="VTR%" name="vtr">
						<InputNumber min={0} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item label="Reporting" name={'reporting'}>
						<ReportingSelect
							onChange={(value) => form.setFieldsValue({ reporting: value })}
							placeholder="Reporting"
						/>
					</Form.Item>
					<Form.Item label="Final Report" name={'finalReport'}>
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ finalReport: value })}
							placeholder="Final Report"
						/>
					</Form.Item>
					<Form.Item label="IO or PO" name={'ioOrPo'}>
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ ioOrPo: value })}
							placeholder="IO or PO"
						/>
					</Form.Item>
					<Form.Item label="Screenshots" name={'screenshots'}>
						<YesNoPendingSelect
							onChange={(value) => form.setFieldsValue({ screenshots: value })}
							placeholder="Screenshots"
						/>
					</Form.Item>
					<Form.Item label="Reconciliation" name={'reconciliation'}>
						<YesNoPendingSelect
							onChange={(value) =>
								form.setFieldsValue({ reconciliation: value })
							}
							placeholder="Reconciliation"
						/>
					</Form.Item>
					<Form.Item label="Status" name="status" initialValue="active">
						<StatusSelect
							onChange={(value) => form.setFieldsValue({ status: value })}
							placeholder="Status"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default AddPlatformModal;
