import { AllSelectsData } from '@/lib/listsApi';
import { ReportsCountData } from '@/lib/reportsApi';
import _ from 'lodash';
import moment from 'moment';

const checkMandatoryFields = (data: any) => {
	const mandatoryFields = [
		'id',
		'country',
		'agency',
		'client',
		'name',
		'startDate',
		'endDate',
		'investment',
		'budget',
		'currency',
		'exchangeRate',
	];
	const neededMandatoryFields = {
		campaign: [] as string[],
	};

	data.forEach((row: any) => {
		mandatoryFields.map((m: string) => {
			let fieldName = '';
			// if (row.column_type === 'campaign' && m.indexOf('p_')) {
			if (row.column_type === 'campaign') {
				//checks only mandatory campaign fields
				fieldName = m.replace('', '');
				if (
					_.isNull(row[m]) &&
					!neededMandatoryFields.campaign.includes(fieldName)
				) {
					neededMandatoryFields.campaign.push(fieldName);
				}
			}
		});
	});

	return neededMandatoryFields;
};

const checkRelationshipFields = (
	parsedInfo: any,
	existingData: AllSelectsData,
	emptyMandatory: boolean
) => {
	const neededRelationshipFields = {
		// salesperson: [] as { name: string }[],
		// country: [] as { name: string }[],
		// agency: [] as { name: string }[],
		product: [] as { name: string }[],
		// client: [] as { name: string }[],
	};
	let campaignRelationshipFields: { keyName: string; name: any }[] = [];

	if (!emptyMandatory) {
		parsedInfo.forEach((info: any, index: number) => {
			if (info.campaign === null) {
				return;
			}

			if (info.column_type === 'campaign') {
				getNeededCamapaignFields(
					info,
					existingData,
					campaignRelationshipFields
				);
			}
		});

		if (campaignRelationshipFields.length > 0) {
			getNeededRelationshipFieldsValue(
				campaignRelationshipFields,
				neededRelationshipFields
			);
		}
	}

	return neededRelationshipFields;
};

function getNeededRelationshipFieldsValue(
	fields: any,
	neededRelationshipFields: any
) {
	let isDuplicated = false;
	fields.forEach((item: any, index: number) => {
		const key = item.keyName as keyof typeof neededRelationshipFields;
		neededRelationshipFields[key].forEach((f: any) => {
			if (f.name === item.name) {
				isDuplicated = true;
			}
		});
		if (!isDuplicated) {
			neededRelationshipFields[key].push({ name: item.name });
		}
	});
}

const getNeededCamapaignFields = (
	info: any,
	existingData: AllSelectsData,
	campaignRelationshipFields: any
) => {
	const country = existingData.countries.find(
		(c) => c.name === info.c_country
	)?.id;
	const agency = existingData.agencies.find(
		(c) => c.name === info.c_agency
	)?.id;
	const salesperson = existingData.salespeople.find(
		(c) => c.name === info.c_salesperson
	)?.id;
	const products = info.c_product ? info.c_product.split(', ') : '';
	const product = info.c_product
		? products.map(
				(p: any) => existingData.products.find((c) => c.name === p)?.id
		  )
		: undefined;
	const client = existingData.clients.find((c) => c.name === info.c_client)?.id;

	Object.entries({
		country,
		agency,
		salesperson,
		product,
		client,
	}).forEach((item) => {
		const key = item[0];
		if (key === 'product' && item[1]) {
			item[1].forEach((it: any, index: number) => {
				if (it === undefined) {
					campaignRelationshipFields.push({
						keyName: key,
						name: products[index],
					});
				}
			});
		} else if (key !== 'product' && item[1] === undefined) {
			campaignRelationshipFields.push({ keyName: key, name: info['' + key] });
		}
	});

	return campaignRelationshipFields;
};

const checkFieldValidation = (parsedInfo: any, emptyMandatory: boolean) => {
	let value1 = parsedInfo[0];
	const needsFieldsValidation = {
		campaignBudget: [] as { name: string }[],
	};

	if (!emptyMandatory) {
		parsedInfo.forEach((item: any, i: number) => {
			if (
				value1.column_type === item.column_type &&
				parsedInfo[i].column_type === 'campaign'
			) {
				value1 = parsedInfo[i];
			}

			if (item.column_type === 'campaign' && item.c_budget > item.c_investment)
				needsFieldsValidation.campaignBudget.push({
					name: item.c_name,
				});
		});
	}

	return needsFieldsValidation;
};

const getDescription = (item: any) => {
	if (item === 'campaignBudget') {
		return 'Budget is greater than its investment';
	}
};

const getReportData = (
	parsedInfo: any,
	allSelects: AllSelectsData | undefined,
	gidPrefix: string,
	gidCount: ReportsCountData | undefined
) => {
	let tempReport: any = {};
	let reportArray: any[] = [];
	let index = 0;

	for (const row of parsedInfo) {
		const gid = `${gidPrefix}.${(gidCount?.reportsCount || 0) + index + 1}`;
		if (row.column_type === 'campaign') {
			tempReport = getReportSchema(row, allSelects, gid) || {};

			if (!tempReport) {
				console.error('Error: tempReport no está definido');
				continue;
			}

			pushCampaignReport(parsedInfo, index, tempReport, reportArray);
		}
		index++;
	}

	return reportArray;
};

const getReportSchema = (row: any, allSelects: any, gid: string) => {
	if (!row) {
		console.error('Error: Row no es válido', row);
		return null;
	}

	return {
		campaign: row.c_name,
		// platforms: { create: [] },
		// platforms: row.platforms?.length > 0 ? { create: row.platforms } : null,
		// platforms: { create: row.platforms || [] },
	};
};

const pushCampaignReport = (
	parsedInfo: any,
	index: number,
	tempReport: any,
	reportArray: any
) => {
	if (!tempReport) {
		console.error('Error: tempReport es undefined en pushCampaignReport');
		return;
	}

	let item = '';
	if (index + 1 <= parsedInfo.length) {
		if (parsedInfo[index + 1] === undefined) {
			item = parsedInfo[index].column_type;
		} else {
			item = parsedInfo[index + 1].column_type;
		}

		if (item === 'campaign') {
			if (tempReport.platforms?.create.length === 0) {
				tempReport.platforms = null;
				return reportArray.push(tempReport);
			}
		}
	}
};

const months = {
	ene: 0,
	feb: 1,
	mar: 2,
	abr: 3,
	may: 4,
	jun: 5,
	jul: 6,
	ago: 7,
	sept: 8,
	oct: 9,
	nov: 10,
	dic: 11,
};

const createDates = (monthString: string) => {
	const monthName = monthString.split('-')[0] as keyof typeof months;
	const monthNumber = months[monthName];
	const year = 2000 + parseInt(monthString.split('-')[1]);
	const startDate = moment({
		year: year,
		month: monthNumber,
		day: 1,
		hour: 10,
	});
	const endDate = moment({ year: year, month: monthNumber, day: 15, hour: 10 });
	return {
		startDate: startDate.toISOString().split('T')[0],
		endDate: endDate.toISOString().split('T')[0],
	};
};

export {
	checkMandatoryFields,
	checkRelationshipFields,
	createDates,
	checkFieldValidation,
	getReportData,
	getDescription,
};
